<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light">FDR : </span>
          <span style="font-size: 22px">{{ fdrName }}</span>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="row col-12 pr-0">
        <div class="col-md-3 mr-auto my-auto">
          <p class="mb-0 h4">Liste initiatives :</p>
        </div>
        <div class="row col-md-9 ml-auto mr-0 pr-0 my-auto">
          <div class="p-0 pl-2 col-md-3 col-12 mb-2 ml-auto">
            <b-form-input
              v-model="searchQuery.libelle"
              type="text"
              placeholder="Libellé initiative"
            ></b-form-input>
          </div>
          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              label="value"
              v-model="searchQuery.responsable"
              :options="responsables"
              :reduce="(responsable) => responsable.id"
              class="bg-white h-100"
              placeholder="Responsable"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>

          <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              v-model="searchQuery.status"
              :options="statusInitiative"
              placeholder="Statut"
              class="bg-white h-100"
            >
              <template #no-options> Liste vide </template>
            </v-select>
          </div>
          <!-- <div class="p-0 pl-2 col-md-3 col-12 mb-2">
            <v-select
              :options="fdrList"
              :reduce="(fdr) => fdr.id"
              label="name"
              v-model="searchQuery.fdr"
              placeholder="FDR"
              class="bg-white h-100"
            >
          <template #no-options > Liste vide </template>
        </v-select>
          </div> -->
        </div>
      </div>
    </div>
    <hr />

    <div class="row">
      <div class="col-12">
        <b-table
          id="table-list"
          responsive
          :items="filteredInitiatives"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy="loadingData"
          show-empty
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(avancement)="data">
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd"
            >
              <b-progress-bar
                :value="data.value || 0"
                :label="`${data.value || 0}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:cell(endingAt)="data">
            <span
              class="text-white text-center px-2 py-1 rounded"
              :style="{
                backgroundColor: dateBackgroundColor(
                  data.item.createdAt,
                  data.value,
                  data.item.status
                ),
              }"
              >{{ data.value }}</span
            >
          </template>

          <template v-slot:cell(responsable)="data">
            <TableResponsable :responsable="data.value" />
          </template>
          <template v-slot:cell(phases)="data">
            <span v-if="data.value.find((phase) => phase.isCurrent)"
              >{{ data.value.find((phase) => phase.isCurrent).libelle }}
            </span>
            <span v-else>-</span>
          </template>

          <template v-slot:cell(libelle)="data">
            <b-link
              :to="{
                name: 'show-initiative',
                params: {
                  slug: data.item.slug || 'abcde',
                  id: data.item.id,
                },
              }"
            >
              {{ data.value }}
            </b-link>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import moment from "moment";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    id: Number,
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        {
          key: "libelle",
          label: "Initiative",
          sortable: true,
        },
        { key: "responsable", label: "Responsable", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "endingAt", label: "Échéances", sortable: true },
        { key: "phases", label: "Phase Projet", sortable: true },
        { key: "avancement", label: "Avancement", sortable: true },
      ],
      tableItems: [],
      loadingData: true,
      fdrName: "",
      searchQuery: {
        libelle: "",
        fdr: "",
        status: "",
        responsable: "",
      },
    };
  },
  methods: {
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
  },
  mounted() {
    // this.fetchFdrInitiatives();
  },
  created() {
    // this.tableItems = this.INITIATIVES;
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("fdr/fetchFdrInitiatives", this.id);
    this.$store.dispatch("status/fetchInitiativeStatus");
  },

  computed: {
    ...mapGetters("fdr", ["FDRS", "INITIATIVES"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["INITIATIVE_STATUS"]),

    responsables() {
      //return array of responsables

      return this.RESPONSABLES.map((responsable) => ({
        id: responsable.id,
        value: `${responsable.firstname} ${responsable.lastname}`,
      }));
    },
    statusInitiative() {
      return this.INITIATIVE_STATUS;
    },
    fdrList() {
      return this.FDRS;
    },

    filteredInitiatives() {
      return this.tableItems
        ? this.tableItems
            .filter(
              (initiative) =>
                (initiative.libelle
                  ? initiative.libelle
                      .toLowerCase()
                      .indexOf(this.searchQuery.libelle.toLowerCase()) > -1
                  : true) &&
                (initiative.status
                  ? initiative.status
                      .toLowerCase()
                      .indexOf(
                        this.searchQuery.status
                          ? this.searchQuery.status.toLowerCase()
                          : ""
                      ) > -1
                  : true)
            )
            .filter((initiative) =>
              this.searchQuery.responsable
                ? initiative.responsable.id ===
                  parseInt(this.searchQuery.responsable)
                : true
            )
            .filter((initiative) =>
              this.searchQuery.fdr
                ? initiative.feuilleRoute.id === parseInt(this.searchQuery.fdr)
                : true
            )
        : this.tableItems;
    },
    rows() {
      return this.filteredInitiatives ? this.filteredInitiatives.length : 0;
    },
  },
  watch: {
    INITIATIVES() {
      this.tableItems = this.INITIATIVES.initiatives;
      this.fdrName = this.INITIATIVES.name;
      this.loadingData = false;
    },
  },
};
</script>
