<template>
  <Kanban @dragged="onDragged" :stages="STATUS_FEUILLES" :blocks="tableItems">
    <div v-for="stage in STATUS_FEUILLES" :slot="stage" :key="stage">
      <h2>
        {{ stage }}
      </h2>
    </div>
    <div v-for="item in items" :slot="item.id" :key="item.id">
      <div>
        <b-progress :value="item.avancement">
          <b-progress-bar
            :style="`background-color:${
              item.status === 'Active'
                ? '#4287f5'
                : item.status === 'Validation fin'
                ? '#a35837'
                : item.status === 'Suspendu'
                ? 'red'
                : item.status === 'Terminé'
                ? '#0f7316'
                : 'gray'
            };`"
            :value="item.avancement"
          ></b-progress-bar>
        </b-progress>
        <div class="d-flex justify-content-between">
          <p class="task-date">{{ item.startedAt }}</p>
          <b-dropdown class="transparent" right>
            <template slot="button-content">
              <i class="mdi mdi-dots-vertical"></i>
            </template>
            <b-dropdown-item
              role="link"
              :to="{
                name: 'show-fdr',
                params: {
                  id: item.id,
                  slug: item.slug || 'abcde',
                },
              }"
              >Consulter</b-dropdown-item
            >
            <b-dropdown-item>Supprimer</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="d-flex">
          <h4 class="task-title my-auto">
            <router-link
              :to="{
                name: 'show-fdr',
                params: {
                  id: item.id,
                  slug: item.slug || 'abcde',
                },
              }"
              >{{ item.name }}</router-link
            >
          </h4>
          <span
            v-b-tooltip.hover="
              echeanceStatus(
                item.createdAt,
                item.endingAt,
                item.status
              ).toUpperCase()
            "
            class="text-white my-auto ml-auto text-center px-2 py-0 rounded"
            :style="{
              backgroundColor: dateBackgroundColor(
                item.createdAt,
                item.endingAt,
                item.status
              ),
            }"
            >{{
              moment(item.endingAt, "DD-MM-YYYY").format("DD/MM/YYYY")
            }}</span
          >
        </div>
        <div class="image-grouped">
          <!-- AVATAR -->

          <b-avatar
            v-b-tooltip.hover="
              item.responsable
                ? item.responsable.firstname + ' ' + item.responsable.lastname
                : '-'
            "
            style="
              background-color: #adb5bd;
              border-color: #adb5bd;
              height: 30px;
              width: 30px;
            "
            class="mr-1"
            :src="item.responsable ? IMG_URL + item.responsable.avatarUrl : ''"
          >
            <div
              v-if="!(item.responsable && item.responsable.avatarUrl)"
              style="
                width: 25px;
                height: 25px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              {{
                item.responsable
                  ? item.responsable.firstname[0].toUpperCase() +
                    item.responsable.lastname[0].toUpperCase()
                  : "-"
              }}
            </div>
          </b-avatar>
        </div>
        <div class="d-lg-flex justify-content-between align-items-center">
          <div style="position: relative; bottom: -10px"></div>
          <small
            style="position: relative; bottom: -10px"
            class="ml-auto text-gray"
          >
            {{
              delayByDays(item.endingAt) >= 0
                ? `
                  Délai de livraison ${delayByDays(item.endingAt)} jour(s)
                 `
                : `${-delayByDays(item.endingAt)} jour(s) de retard`
            }}
          </small>
        </div>
      </div>
    </div>
    <div v-if="$route.path.includes('goals')"></div>

    <div
      v-else
      v-for="stage in STATUS_FEUILLES"
      :key="stage"
      :slot="`footer-${stage}`"
      @click="$router.push({ name: 'add-fdr' })"
      style="cursor: pointer !important"
    >
      <span href="">+ Ajouter un nouveau bloc</span>
    </div>
  </Kanban>
</template>
<script>
import Kanban from "@/components/apps/Kanban";
import { IMG_URL } from "@/helpers/services/api";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: {
    Kanban,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      IMG_URL,
      // statuses: ["Proposé", "Validation fin", "Active", "Suspendu", "Terminé"],
      tableItems: [],
    };
  },
  methods: {
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    onDragged: function (e, f) {
      this.$store.dispatch("fdr/updateFdr", {
        id: e,
        status: f,
      });
      this.tableItems = this.tableItems.map((item) => {
        if (item.id == e) item.status = f;
        return item;
      });
    },
    onDelay: function (endingAt) {
      var currentDate = moment().format("DD-MM-YYYY")._i;
      return moment(endingAt, "DD-MM-YYYY").isBefore(currentDate);
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
    delayByDays(date) {
      return parseInt(
        moment
          .duration(
            moment(date, "DD-MM-YYYY").diff(moment(new Date(), "DD-MM-YYYY"))
          )
          .asDays()
      );
    },
  },
  computed: {
    ...mapGetters("cStatus", ["STATUS_FEUILLES"]), //get all status
  },

  watch: {
    items: function () {
      this.tableItems = this.items;
    },
    tableItems: function () {
      this.tableItems = this.items;
    },
  },
};
</script>

<style>
.board-wrapper .portlet-card-list {
  padding-left: 0;
  list-style: none;
  min-height: 70px;
  margin-bottom: 16px;
  overflow: hidden;
  max-height: 100vh;
  overflow-y: auto;
}
.drag-container {
  overflow-x: auto;
}
</style>
